<template>
    <v-row class="h-100">
        <background page="subscriptions" v-if="!isLoading && subscriptions.length > 0"></background>

        <v-col v-if="!loading" class="h-100">
            <v-row v-if="subscriptions.length > 0">
                <v-col cols="12">
                    <v-card flat class="rounded-xl shadow" v-if="!loading && subscriptions.length > 0">
                        <v-card-title class="justify-center petu-green">Suscripciones</v-card-title>
                        <v-list two-line>
                            <template v-for="(item, index) in subscriptions">
                                <v-list-item :key="item.name">
                                    <v-list-item-content>
                                        <v-list-item-title class="d-flex justify-space-between align-end" style="overflow: unset">
                                            <router-link :to="`/producto/${getProductSlug(item.productId)}`" style="text-decoration: none; color: black !important">{{ getProductName(item.productId) }}</router-link>
                                            <div v-if="hasPaymentMethod && !isCardExpired && isRenewable( item.productId )">
                                                <v-switch v-if="!isExpired(item.nextPaymentDate)" v-model="item.autoRenew" @change="saveSubscription(item)" class="ma-0" dense hide-details></v-switch>
                                                <v-btn v-else @click="addToCart(item)" class="no-uppercase" color="primary" small text>
                                                    Renovar
                                                    <v-icon small class="ml-2">mdi-autorenew</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="d-flex align-center" :class="{ 'red--text': isExpired(item.nextPaymentDate) }">
                                            <v-icon v-if="isExpired(item.nextPaymentDate)" class="mr-1" color="red" small>mdi-alert-circle-outline</v-icon>
                                            <span>{{ getExpiryDateText(item.nextPaymentDate) }}</span>
                                        </v-list-item-subtitle>
                                        <v-list v-if="!isExpired(item.nextPaymentDate) && isOnline(item.productId)">
                                            <template v-for="(r, index) in getProductResources( item.productId )">
                                                <v-list-item :key="`resource-${index}`" @click="!hasUploaded(item.productId, r.id) ? openResource(r, item.productId) : null">
                                                    <v-list-item-content class="py-0">
                                                        <v-list-item-title v-text="r.name"></v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-btn v-if="!hasUploaded(item.productId, r.id)" icon @click="openResource(r, item.productId)"><v-icon>{{ getIconFromType(r.type) }}</v-icon></v-btn>
                                                        <v-icon v-else class="mr-1" color="green darken-2">mdi-check-circle-outline</v-icon>
                                                    </v-list-item-action>
                                                </v-list-item>
                                                <v-divider inset v-if="index < getProductResources(item.productId).length - 1" :key="index"></v-divider>
                                            </template>
                                            <v-list-item-subtitle v-if="getProductResources(item.productId).length === 0">
                                                <div class="d-flex align-center">
                                                    <v-icon small class="mr-1"  color="orange">mdi-alert-outline</v-icon>
                                                    <span>No hay recursos para hoy</span>
                                                </div>
                                            </v-list-item-subtitle>
                                        </v-list>
                                        <v-list-item-subtitle v-else-if="!isOnline(item.productId) && item.remainingSessions">{{ item.remainingSessions }} {{ item.remainingSessions > 1 ? 'sesiones faltantes' : 'sesión faltante' }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider v-if="index < subscriptions.length - 1" :key="index"></v-divider>
                            </template>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else class="h-100" align="center" color="blue" style="margin-top: -75px">
                <v-col class="text-caption">
                    <v-card class="rounded-xl">
                        <v-card-text class="text-center">
                            <v-icon size="75" class="petu-orange mb-3">mdi-alert-circle</v-icon>
                            <div>
                                <span>{{ alertText }}</span>
                                <span v-if="!isLoggedIn" class="ml-1"><router-link to="/login" class="link">aquí</router-link></span>
                            </div>
                            <router-link v-if="isLoggedIn" to="/productos" color="blue darken" class="link">Ver productos</router-link>
                        </v-card-text>
                    </v-card>
                 </v-col>
            </v-row>
        </v-col>

        <resource-detail v-model="showResource" :resource="selectedResource" :product-id="selectedProductId" @saved="reload"></resource-detail>

        <!-- TODO: make component -->
        <v-dialog v-if="isLoggedIn && subscriptions.length > 0" v-model="isLoading" hide-overlay persistent content-class="rounded-lg" width="300">
            <v-card class="petu-green-bg" dark>
                <v-card-text> 
                    <span v-if="loading">Cargando subscripciones</span>
                    <span v-else>Renovando suscripción</span>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { PetuProduct } from '@/classes/product'
import { PetuCustomer } from '@/classes/customer'
import { PetuSubscription } from '@/classes/subscription'
import { TrainingType } from '@/models'
import moment from 'moment';
import { _st } from '@/softech';

import ResourceDetail from '@/components/ResourceDetail'
import Background from '@/components/Background'


export default {
	name: 'Subscriptions',
    components: { ResourceDetail, Background },
    data:() => ({
        subscriptions: [],
        products: [],
        resource: {},
        loading: true,
        renewLoading: false,
        days: {
            monday: { text: 'Lunes', value: 1 },
            tuesday: { text: 'Martes', value: 2 },
            wednesday: { text: 'Miércoles', value: 3 },
            thursday: { text: 'Jueves', value: 4 },
            friday: { text: 'Viernes', value: 5 },
            saturday: { text: 'Sábado', value: 6 },
            sunday: { text: 'Domingo', value: 0 },
            weekdays: { text: 'semana', value: 7 },
            weekends: { text: 'fin de semana', value: 8 }
        },

        showResource: false,
        selectedResource: {},
        selectedProductId: '',

        customerResources: [],
    }),
    async mounted() {
        if( this.isLoggedIn ) {
            let customer = new PetuCustomer(this.customer);

            await customer.getSubscriptions();
            this.subscriptions = customer.data.subscriptions;

            this.products = await this.getProducts();
            this.customerResources = await customer.getResources();
        }

        this.loading = false;
    },
    methods: {
        ...mapActions({ updateCustomer: 'auth/updateCustomer', addToCartAction: 'cartInfo/addToCart' }),
        async reload() {
            this.customerResources = await customer.getResources();
        },
        async getProducts() {
            let products = [];
            for( const subscription of this.subscriptions ) {
                let product = new PetuProduct();
                await product.load( subscription.productId );
                await product.loadResources();
                products.push( product.data );
            }

            return products;
        },
        getProductName(id) { 
            const p = this.products.find((p) => p.id === id)
            return p ? p.name : ''
        },
        getProductSlug(id) {
            const p = this.products.find((p) => p.id === id)
            return p ? p.slug : ''
        },
        getExpiryDateText(date) {
            let verb = this.isExpired(date) ? 'Expiró' : 'Expira' 
            return `${verb} en ${(new Date(date)).toLocaleDateString()}` 
        },
        isExpired( date ) {
            date = new Date(date);
            return date < (new Date());
        },
        isOnline( id ) {
            return this.products.find((p) => p.id === id)?.trainingType === 'online';
        },
        getProductResources( id ) {
            let product = this.products.find((p) => p.id === id);
            if( product && product.resources.length > 0 ) {
                return product.resources.filter((r) => {

                    if( this.hasUploaded( id, r.id ) ) {
                        return false;
                    } 
                    else if( r.onlyOn ) {
                        const date = new Date();
                        const d = new Date(date.getTime() - date.getTimezoneOffset() * 60000).getDay();
                        
                        // if( d === 6 ) 
                        //     return true;

                        for( let day of r.availableOn ) {
                            if( d === this.days[day].value ) 
                                return true;
                        }

                        return false;
                    } 
                    else {
                        return true;
                    }
                })
            } 
            else {
                return [];
            }
        },
        getIconFromType(type) {
            switch (type) {
                case 'video'    : return 'mdi-video'
                default         : return ''    
            }
        },
        hasUploaded( productId, resourceId ) {
            if( _st.isNU( this.customerResources ) ) 
                return false;
            
            return this.customerResources.findIndex((r) => {
                return r.productId === productId && r.resourceId === resourceId
            }) > -1;
        },
        openResource(resource, productId) {
            this.selectedResource = resource;
            this.selectedProductId = productId;
            this.showResource = true;
        },
        async saveSubscription(subscription) {
            try {
                subscription = new PetuSubscription( subscription );
                subscription.updateAutoRenew( subscription.data.autoRenew );
                console.log(subscription);
                this.$root.$emit('alert', { status: 'success', message: 'Su suscripción ha sido guardada exitosamente', centered: true });
            } 
            catch( err ) {
                console.log(err);
                this.$root.$emit('alert', { status: 'error', message: 'Ha ocurrido un error', centered: true });
            }
        },
        async addToCart(subscription) {
            try {
                if (!this.isExpired(subscription.nextPaymentDate)) return
                this.renewLoading = true
                await this.addToCartAction({ productId: subscription.productId, qty: 1 })
                this.$router.push('/checkout')
            } 
            catch(err) {
                console.log(err);
                this.renewLoading = false;
                this.$root.$emit('alert', { status: 'error', message: 'Ha ocurrido un error', centered: true });
            }
        },
        isRenewable( productId ) {
            const i = this.products.findIndex((p) => p.id === productId);

            if( i === -1 ) 
                return false;

            const trainingType = this.products[i].trainingType;
            switch( trainingType ) {
                case 'online':
                case 'one on one':
                case 'presencial': 
                    return true;
                default: 
                    return false;
            }
        }
    },
    computed: {
        ...mapGetters({ 
            customer: 'auth/customer', 
            hasPaymentMethod: 'auth/hasPaymentMethod', 
            isLoggedIn: 'auth/isLoggedIn' 
        }),
        isLoading() { return this.loading || this.renewLoading },
        alertText() { 
            return this.isLoggedIn
            ? 'Actualmente no tiene suscripciones'
            : 'Para poder ver sus suscripciones, ingrese a su cuenta'
        },
        isCardExpired() {
            if( this.hasPaymentMethod ) {
                let expired = true;
                this.customer.cards.forEach(c => {
                    if( moment().isBefore( moment(`${c.expYear}-${c.expMonth}-01`) ) ) {
                        expired = false;
                        return;
                    }
                });
                
                return expired;
            } 
            else {
                return true;
            }
        },
    }
}
</script>

<style scoped>
.shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}
</style>