<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay >
      <v-card tile>
        <v-toolbar dark class="petu-green-bg" dense flat>
            <v-toolbar-title>{{ resource.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        
        <video v-show="resource.type === 'video'" height="240" style="width:100%" controls ref="video">
            <source ref="videoSource" type="video/mp4">
            Your browser does not support the video tag.
        </video> 

        <v-card-text>
            <div v-html="resource.description" class="py-3"></div>

            <v-divider v-if="resource.instructions"></v-divider>

            <div v-if="resource.instructions" class="py-3">
                <h3 class="text-h6 pb-3">Instrucciones</h3>
                <div v-html="resource.instructions"></div>
            </div>

            <div class="upload" v-if="!hasUploaded">
                <input type="file" ref="resource" class="d-none" accept="video/*" multiple="false" @change="uploadResource">
                <v-btn :loading="uploading" @click="$refs.resource.click()" block depressed dark tile large class="no-uppercase petu-orange-bg mt-3">
                    <span>Subir evidencia</span>
                    <v-icon small class="ml-3">mdi-upload</v-icon>
                </v-btn>
            </div>

            <div v-else  class="upload">
                <v-icon class="mr-2" color="green darken-2">mdi-check-circle-outline</v-icon>
                <span>Ya ha subido la evidencia para este recurso</span>
            </div>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import { Storage } from 'aws-amplify'
import { _st } from '@/softech'
import { PetuCustomer } from '@/classes/customer'
import { PetuErrorLog } from '@/classes/log'

export default {
    name: 'ResourceDetail',
    props: {
        value: { type: Boolean, default: false },
        resource: { type: Object, default() { return {}; } },
        productId: { type: [String, Number], default: 0 }
    },
    data() {
        return {
            dialog: this.value,
            uploading: false,
        }
    },
    methods: {
        ...mapActions({ 
            updateCustomer: 'auth/updateCustomer' 
        }),
        async loadResource() {
            if( this.resource ) {
                
                let src = await Storage.get(this.resource.src.key, { level: "protected", expires: 180, identityId: this.resource.src.identityId });
                
                if( _st.isNU( this.$refs.videoSource ) ) {
                    setTimeout( () => {
                        this.$refs.videoSource.src = src;
                        this.$refs.video.load();
                    }, 500 );
                    return;
                }

                this.$refs.videoSource.src = src;
                this.$refs.video.load();
                return;
            }
        },
        async uploadResource() {
            const file = this.$refs.resource.files[0];
            if( !['video/mp4', 'video/quicktime', 'video/x-ms-wmv', 'video/x-msvideo', 'video/x-matroska'].includes( file.type ) ) {
                // notificar que es format inválido
                this.$root.$emit('alert', {
                    status  : 'error',
                    message : 'Formato inválido'
                });
                return;
            }

            this.uploading = true;
            let customer = new PetuCustomer( this.customer );

            await customer.uploadResource({
                resourceID: this.resource.id,
                productID: this.productId,
                customerID: this.customer.id,
                resource: file
            }).then(async () => {
                await customer.load(this.customer.id)
                this.updateCustomer({ customer })
                this.dialog = false;
                this.$root.$emit('alert', { status: 'success', message: 'Se ha guardado su evidencia' });
                this.$emit('saved');
            }).catch(async (err) => {
                console.log(err)
                this.$root.$emit('alert', { status: 'error', message: 'Ha ocurrido un error' })
            }).finally(() => this.uploading = false)
        },
        clearResource() {
            if( _st.isNU( this.$refs.video ) ) 
                return;

            this.$refs.video.pause();
            this.$refs.videoSource.removeAttribute('src');
            this.$refs.video.load();
        }
    },
    computed: {
        ...mapGetters({ 
            customer    : 'auth/customer', 
            isLoggedIn  : 'auth/isLoggedIn',
        }),
        hasUploaded() {
            if( _st.isNU( this.customer.resources ) ) 
                return false;

            return this.isLoggedIn && this.customer.resources.length > 0
                ? this.customer.resources.findIndex((r) => r.productID === this.productId && r.resourceID === this.resource.resourceId) > -1
                : false;
        }
    },
    watch: {
        async dialog(v) { 
            if( v ) await this.loadResource();
            else    this.clearResource();

            this.$emit('input', v);
        },
        value( v ) {
            this.dialog = v;
        }
    },
    beforeDestroy() {
        this.clearResource()
    }
}
</script>

<style scoped>
.upload {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>